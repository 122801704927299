@font-face {
  font-family: 'MainBasketRun';
  src: url('./assets/static/fonts/Ruddy-Bold.otf');
  /* src: url('./assets/static/fonts/FagoCoLf-Bold.ttf'); */
  /* src: url('./assets/static/fonts/LuckiestGuy-Regular.ttf'); */
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/static/fonts/Poppins-Regular.ttf');
  /* font-weight: bold; */ 
  font-style: normal;
  font-display: swap;
}


body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: #06BDCE;
    background: linear-gradient(#06BDCE, #F70101);
  }



canvas {
    /* width: 100%; */
      background: linear-gradient(#06BDCE, #F70101);
      position: absolute;
      /*top: 0;*/
      /*left: 50%;*/
      /*transform: translate(-50%, 0);*/
      width: 100%;
	    height: 100%;
}

#fontsCont {
    font-family: 'MainBasketRun';
    font-size: 2pt;
    font-weight: bold;
    position: absolute;
}

#reportMessages {
  padding: 5px;
  color: white;
  z-index:10;
  position: absolute;
  font-size: 12pt;
  left: 10px;
  bottom:100px;
  width: 200px;
  opacity: 0.63;
  display:none;

}


#preloader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: url('./assets/bg-cells.png') center #00bbc9;
  align-items: center;
  z-index: 10;
}

.preloader-container {
  text-align: center;
}

#preloader img.logo {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
}

.image-text-container {
  display: inline-block;
  position: relative;
}

.image-text-container img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.image-text-container p {
  display: inline-block;
  color: white;
  font-size: 24px;
  margin-left: 10px;
  font-weight: 900;
  text-align: left;
  width: 50px;
  margin:0 0 0 -3px;
  /* margin-left: -11px; */
}

.tutorial-overlay{
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: #06BDCE;; */
}

#tutorial-cont {
  /* display:none; */
  font-family: MainBasketRun;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  max-width: 800px;
  margin: auto;
  padding: 0px;
  color: white;
  border-radius: 32px;
  border: 4px solid #e8ba00;
  /* background-color: #e8ba00; */

  background-image: url('./assets/dom/bg-cells.png');
  background-repeat: repeat-y repeat-x;
  background-color: #00bbc9;

}

#tutorial-header {
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background:none;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

#tutorial-header h2 {

  margin: 0; /* Remove default margin for <h2> */
  text-align: center; /* Center the text */
  flex-grow: 1;
  width: calc(100% - 20px);
  margin-left: 50px;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;

  color: #FFF;
  text-align: center;
  text-shadow: 1.298px 1.298px 0px #910C04;

  -webkit-text-stroke-width: 0.6489148139953613;
  -webkit-text-stroke-color: #E53F3F;
  font-family: MainBasketRun;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;


}

#tutorial-header .close-btn{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 32px;
  cursor: pointer;
}

#tutorial-header .close-btn img{
  height: 32px;
  width: 32px;
  margin-top: -50px;
  margin-right: -10px;
}


#tutorial-content {
  flex-grow: 1;
  margin-top: 60px;
  overflow-y: auto;
  /* background: #031d21; */
  padding: 10px;
  border-radius: 0 0 32px 32px;
/*   background-image: url('./assets/dom/bg-cells.png');
  background-repeat: repeat-y repeat-x;
  background-color: #00bbc9; */
}

#tutorial-content .inner-content{
  margin: 12px;
}

#tutorial-content .block {
  margin-bottom: 32px;
}


#tutorial-content h4 {
  color: var(--neutral-neutral-0000, #FFF);
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.40);

  /* Body/Bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */

  padding-top: 10px;
  font-size: 24px;
  margin: 16px 0 20px 0;
  
}

#tutorial-content h6 {
  font-size: 16px;
  font-weight: 900;

  color: var(--neutral-neutral-0000, #FFF);
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.40);

  /* Body/Bold */
  font-family: MainBasketRun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
}

#tutorial-content p {
  color: var(--neutral-neutral-0000, #FFF);
  text-shadow: 0.5px 0.5px 0px #4E0202;
  
  /* Footnote/Medium */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

#tutorial-content .product-line {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-line {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
      justify-content: center;
      align-items: center;
}

.item-content {
  flex-grow: 1;
}


.items-list .item  {
  padding: 0;
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}

.items-list .item img  {
  padding: 10px 16px 10px 10px;
}

.block .shape {
  text-align: center;
  padding-top: 15px;
}

.cart-control {
  height: 200px;
  margin-bottom: 20px;
  /* background:  url('./assets/cart.png') center no-repeat; */
}

.cart-control .line2 {
  text-align: center;
  
}

.cart-control .line2 img{
  text-align: center;
  
}

.cart-control .line1  {
  text-align: center;
  width: 110%;
  margin-left: -15px;
}
.cart-control .line1 img {
  display: inline-block;
}
.cart-control .line1 img:nth-of-type(2) {

}

.cart-control  .line2 img:nth-of-type(2) {

  
}

h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}
