@font-face {
  font-family: MainBasketRun;
  src: url("Ruddy-Bold.7116b43b.otf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Regular.d5d72893.ttf");
  font-style: normal;
  font-display: swap;
}

body {
  background: linear-gradient(#06bdce, #f70101);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

canvas {
  background: linear-gradient(#06bdce, #f70101);
  width: 100%;
  height: 100%;
  position: absolute;
}

#fontsCont {
  font-family: MainBasketRun;
  font-size: 2pt;
  font-weight: bold;
  position: absolute;
}

#reportMessages {
  color: #fff;
  z-index: 10;
  opacity: .63;
  width: 200px;
  padding: 5px;
  font-size: 12pt;
  display: none;
  position: absolute;
  bottom: 100px;
  left: 10px;
}

#preloader {
  z-index: 10;
  background: #00bbc9 url("bg-cells.cbbf5496.png") center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader-container {
  text-align: center;
}

#preloader img.logo {
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.image-text-container {
  display: inline-block;
  position: relative;
}

.image-text-container img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.image-text-container p {
  color: #fff;
  text-align: left;
  width: 50px;
  margin: 0 0 0 -3px;
  font-size: 24px;
  font-weight: 900;
  display: inline-block;
}

.tutorial-overlay {
  z-index: 2;
  background-color: #000c;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

#tutorial-cont {
  z-index: 2;
  color: #fff;
  background-image: url("bg-cells.cbbf5496.png");
  background-repeat: repeat-y repeat-x;
  background-color: #00bbc9;
  border: 4px solid #e8ba00;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  width: 335px;
  max-width: 800px;
  height: 80vh;
  margin: auto;
  padding: 0;
  font-family: MainBasketRun;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#tutorial-header {
  text-align: center;
  z-index: 3;
  background: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

#tutorial-header h2 {
  text-align: center;
  color: #fff;
  text-align: center;
  text-shadow: 1.298px 1.298px #910c04;
  -webkit-text-stroke-width: .648915;
  -webkit-text-stroke-color: #e53f3f;
  flex-grow: 1;
  width: calc(100% - 20px);
  margin: 0 0 0 50px;
  font-family: MainBasketRun;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

#tutorial-header .close-btn {
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
}

#tutorial-header .close-btn img {
  width: 32px;
  height: 32px;
  margin-top: -50px;
  margin-right: -10px;
}

#tutorial-content {
  border-radius: 0 0 32px 32px;
  flex-grow: 1;
  margin-top: 60px;
  padding: 10px;
  overflow-y: auto;
}

#tutorial-content .inner-content {
  margin: 12px;
}

#tutorial-content .block {
  margin-bottom: 32px;
}

#tutorial-content h4 {
  color: var(--neutral-neutral-0000, #fff);
  text-shadow: 1px 2px #0006;
  margin: 16px 0 20px;
  padding-top: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

#tutorial-content h6 {
  color: var(--neutral-neutral-0000, #fff);
  text-shadow: 1px 2px #0006;
  font-family: MainBasketRun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

#tutorial-content p {
  color: var(--neutral-neutral-0000, #fff);
  text-shadow: .5px .5px #4e0202;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

#tutorial-content .product-line {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-line {
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.item-content {
  flex-grow: 1;
}

.items-list .item {
  align-items: flex-start;
  margin-top: 8px;
  padding: 0;
  display: flex;
}

.items-list .item img {
  padding: 10px 16px 10px 10px;
}

.block .shape {
  text-align: center;
  padding-top: 15px;
}

.cart-control {
  height: 200px;
  margin-bottom: 20px;
}

.cart-control .line2, .cart-control .line2 img {
  text-align: center;
}

.cart-control .line1 {
  text-align: center;
  width: 110%;
  margin-left: -15px;
}

.cart-control .line1 img {
  display: inline-block;
}

h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}

/*# sourceMappingURL=index.4a8b3da9.css.map */
